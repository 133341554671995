var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "dropdown filterDropdown", attrs: { cols: "12" } },
        [
          _c(
            "div",
            {
              staticClass: "filter-header",
              attrs: { id: _vm.createFilterId({ header: true }) },
            },
            [
              _c(
                "h2",
                { staticClass: "mb-0" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.createFilterId(),
                          expression: "createFilterId()",
                        },
                      ],
                      staticClass: "btn btn-link btn-block text-left collapsed",
                      attrs: { id: _vm.createFilterId({ trigger: true }) },
                    },
                    [
                      [
                        _c("span", [_vm._v(_vm._s(_vm.title))]),
                        _vm._v(" "),
                        _c("b-icon", { attrs: { icon: "chevron-down" } }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: _vm.createFilterId() } },
            _vm._l(_vm.attrs, function (attr, i) {
              return _c(
                "div",
                { key: `${attr}-${i}`, staticClass: "filter-body" },
                [
                  _c("ais-panel", {
                    scopedSlots: _vm._u(
                      [
                        attr.header
                          ? {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("h3", { staticClass: "filter-title" }, [
                                    _vm._v(_vm._s(_vm.headerTitle(attr.name))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "default",
                          fn: function ({ hasRefinements }) {
                            return [
                              _c("ais-refinement-list", {
                                attrs: {
                                  limit: "1000",
                                  attribute: attr.name,
                                  "sort-by": ["name:asc"],
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        items,
                                        refine,
                                        createURL,
                                      }) {
                                        return [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "ais-RefinementList-list trim-filter-list",
                                            },
                                            _vm._l(items, function (item) {
                                              return _c(
                                                "li",
                                                {
                                                  key: item.value,
                                                  class: {
                                                    "ais-refinementList-item": true,
                                                    isRefined: item.isRefined,
                                                    colorItem: attr.colorItem,
                                                  },
                                                  attrs: {
                                                    disabled: !hasRefinements,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      class: {
                                                        "aisRefinementList-link": true,
                                                        colorLink:
                                                          attr.colorItem,
                                                      },
                                                      attrs: {
                                                        href: createURL(
                                                          item.value
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return refine(
                                                            item.value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            "ais-RefinementList-label",
                                                            "mr-1",
                                                            attr.colorItem
                                                              ? `${_vm.colorMatch(
                                                                  item.label
                                                                )}Label`
                                                              : "",
                                                          ],
                                                        },
                                                        [
                                                          attr.colorItem
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "swatch",
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ais-RefinementList-count",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                item.count
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }